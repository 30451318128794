import React from 'react';
import renewableEnergyImage from '../images/renewal_energy.jpg';
import sustainableTransportImage from '../images/sustainable_transport.jpg'
import reforestationImage from '../images/reforestation.jpg'
const ClimateChange = () => {
  return (
    <section id="climate-change" className="section">
      <div className="container">
        <h2 className="section-title">Climate Change Solutions</h2>
        <div className="card-grid">
          <div className="card">
            <img src={renewableEnergyImage} alt="Renewable Energy" className="card-image" />
            <div className="card-content">
              <h3 className="card-title">Renewable Energy</h3>
              <p className="card-description">Discover how clean energy sources like solar and wind power can help combat climate change.</p>
              <a href="#hero" className="card-link">Learn More</a>
            </div>
          </div>
          <div className="card">
            <img src={sustainableTransportImage} alt="Sustainable Transport" className="card-image" />
            <div className="card-content">
              <h3 className="card-title">Sustainable Transport</h3>
              <p className="card-description">Explore eco-friendly transportation options that reduce carbon emissions.</p>
              <a href="#hero" className="card-link">Learn More</a>
            </div>
          </div>
          <div className="card">
            <img src={reforestationImage} alt="Reforestation" className="card-image" />
            <div className="card-content">
              <h3 className="card-title">Reforestation</h3>
              <p className="card-description">Learn about the importance of planting trees and preserving forests to combat climate change.</p>
              <a href="#hero" className="card-link">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClimateChange;
