import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PlaycardsModal = ({ closeModal }) => {
  return (
    <div className="modal playcards-modal" style={modalStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <span className="close" onClick={closeModal} style={closeStyle}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h2>Environment & Climate Professions Flashcards</h2>
        <div className="playcards-video" style={videoStyle}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/S44QlpSo2NE?si=pxsb7OhhlaoivMng" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <p>
          Introducing our new Environment & Climate Professions Flashcards! These fun and educational cards feature various climate-related jobs, helping children learn about exciting career opportunities in environmental protection and sustainability.
        </p>
      </div>
    </div>
  );
};

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  zIndex: 1000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.5)'
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '5px',
  width: '80%',
  maxWidth: '700px',
  textAlign: 'center',
  position: 'relative'  // Ensures the close button is positioned relative to the modal content
};

const closeStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer'
};

const videoStyle = {
  marginBottom: '20px'
};

export default PlaycardsModal;
