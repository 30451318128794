import React, { useEffect } from 'react';
import { Link } from 'react-scroll';

const Hero = () => {
  useEffect(() => {
    // Add event listeners and other JavaScript functionality here
    const ctaButton = document.querySelector('.cta-button');
    ctaButton.addEventListener('mouseenter', (e) => {
      e.target.style.transform = 'scale(1.1) rotate(5deg)';
    });

    ctaButton.addEventListener('mouseleave', (e) => {
      e.target.style.transform = 'scale(1) rotate(0deg)';
    });

    // Add parallax effect
    window.addEventListener('mousemove', (e) => {
      const elements = document.querySelectorAll('.floating, .sun, .recycle');
      const mouseX = e.clientX / window.innerWidth;
      const mouseY = e.clientY / window.innerHeight;
      
      elements.forEach((el) => {
        const movementX = (mouseX - 0.5) * 30;
        const movementY = (mouseY - 0.5) * 30;
        el.style.transform = `translate(${movementX}px, ${movementY}px)`;
      });
    });

    // Add other JavaScript functionality as needed

    // Clean up event listeners on component unmount
    return () => {
      ctaButton.removeEventListener('mouseenter', () => {});
      ctaButton.removeEventListener('mouseleave', () => {});
      window.removeEventListener('mousemove', () => {});
    };
  }, []);

  return (
    <section className="hero">
      <div className="clouds">
        <div className="cloud cloud1"></div>
        <div className="cloud cloud2"></div>
        <div className="cloud cloud3"></div>
      </div>

      <div className="sun"></div>

      <h1>Eco Adventures for Young Explorers</h1>
      <p className="subtitle">Join us on a journey through enchanting stories that educate and empower children about climate change and environmental stewardship !</p>
      <div className="cta-button-wrapper">
        <Link to="books" className="cta-button" smooth={true} duration={500}>Dive into Our Books</Link>
      </div>
      <div className="tree floating tree1">
        <div className="tree-top"></div>
        <div className="tree-trunk"></div>
      </div>
      <div className="tree floating tree2">
        <div className="tree-top"></div>
        <div className="tree-trunk"></div>
      </div>
      
      <div className="world floating">
        <div className="continent continent1"></div>
        <div className="continent continent2"></div>
      </div>

      <svg className="recycle" viewBox="0 0 24 24" fill="#ffffff">
        <path d="M21.82,15.42L19.32,19.75C18.83,20.61 17.92,21.06 17,21H15V23L12.5,18.5L15,14V16H17.82L15.6,12.15L19.93,9.65L21.73,12.77C22.25,13.54 22.32,14.57 21.82,15.42M9.21,3.06H14.21C15.19,3.06 16.04,3.63 16.45,4.45L17.45,6.19L19.18,5.19L16.54,9.6L11.39,9.69L13.12,8.69L11.71,6.24L9.5,10.09L5.16,7.59L6.96,4.47C7.37,3.64 8.22,3.06 9.21,3.06M5.05,19.76L2.55,15.43C2.06,14.58 2.13,13.56 2.64,12.79L3.64,11.06L1.91,10.06L7.05,10.14L9.7,14.56L7.97,13.56L6.56,16H11V21H7.4C6.47,21.07 5.55,20.61 5.05,19.76Z"/>
      </svg>
    </section>
  );
};

export default Hero;
