import React from 'react';
import authorImage from '../images/_AK_0657.JPG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faMedium, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';

const AboutAuthor = () => {
  return (
    <section id="about-author" className="section">
      <div className="container">
        <h2 className="section-title">Meet the Author</h2>
        <div className="author-content">
          <div className="author-image">
            <img src={authorImage} alt="Reshuka Jain" />
            <div className="floating-leaves">
              {[...Array(15)].map((_, i) => <div key={i} className="leaf"></div>)}
            </div>
          </div>
          <div className="author-gap"></div>
          <div className="author-info">
            <h3 className="author-name">Reshuka Jain</h3>
            <p className="author-bio">An innovative educator and climate advocate, Reshuka masterfully blends storytelling with environmental awareness. Her 8+ years of experience, including work with Teach For India, have honed her ability to transform complex concepts into engaging narratives for young minds. Driven by a vision of preserving childhood wonder in an ever-changing world, Reshuka crafts compelling tales that inspire eco-consciousness and holistic development in the next generation.</p>
            <div className="social-links">
              <a href="https://amzn.in/d/0hAReniU" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAmazon} /></a>
              <a href="https://reshukajain.medium.com/" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMedium} /></a>
              <a href="https://www.instagram.com/reshuka_jain?igsh=MTA3ZXI4anc2b2lqZA%3D%3D&utm_source=qr" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
              <a href="https://www.linkedin.com/in/reshuka-jain-88a01916b?utm_source=share&utm_campaign=share_via&utm_content=profile" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              <a href="https://www.youtube.com/@KukiMani" className="social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAuthor;
