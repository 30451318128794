import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';


const Testimonials = () => {
  const testimonials = [
    {
      content: "The book series offers an engaging and educational exploration of climate change for young audiences. We loved the intergenerational knowledge sharing, hands-on activities, and vocabulary corner that foster environmental awareness in children.",
      author: "Nikita Dhanesha: Mom, Digital creator"
    },
    {
      content: "Reshuka's thoughtful series makes complex environmental concepts easy for children. Age-appropriate language, amazing illustrations, and visual reinforcement enhance the learning experience.",
      author: "Poonam Chatterjee: Educator, Teach For India Alum"
    },
    {
      content: "The captivating adventures of Kuki and Mani address environmental issues in a kid-friendly way. My 6-year-old loved the characters, stories, quizzes, and beautiful graphics. We're excited for more lessons on sustainability!",
      author: "Sonam Goyal: Mom, Chief People's Officer"
    }
  ];

  return (
    <section id="testimonials" className="section">
      <div className="container">
        <h2 className="section-title">What Readers Say</h2>
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />  
              <p className="testimonial-content">{testimonial.content}</p>
              <p className="testimonial-author">- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
