import React from 'react';
import book1Image from '../images/Book 1_V1 (8x10 in).png';
import book2Image from '../images/Book 2_V1.png';
import book3Image from '../images/Book 3_V1.png';
import book4Image from '../images/Book 4_V1.png';
import book5Image from '../images/Book 5_V1.png';
import ProductCatalog1 from '../images/ProductCatalog1.png';
import ProductCatalog2 from '../images/ProductCatalog2.png';
import ProductCatalog3 from '../images/ProductCatalog3.png';

const Books = ({ openModal, openPlaycardsModal }) => {
  const books = [
    {
      id: "kuki-magicalmirror",
      title: "Kuki and the Magical Mirror",
      image: book1Image,
      description: "Kuki sees Earth's bleak future in a magic mirror. With friend Mani and wise Dadi, she embarks on an eco-quest to learn about climate change. Join their adventure to see if small hands can make a big difference for our planet!"
    },
    {
      id: "carbon-culprit",
      title: "Carbon is the Culprit",
      image: book2Image,
      description: "Kuki and Mani become eco-detectives when Rangapattanam's crops fail. Guided by Dadi Devi, they uncover the Carbon Cycle's secrets. This magical tale educates and inspires action through curiosity."
    },
    {
      id: "camping-adventure",
      title: "The Camping Adventure",
      image: book3Image,
      description: "Kuki's campfire mishap turns a family trip into an eco-adventure. The group must unite to tame the flames and learn valuable environmental lessons. Experience their thrilling journey of friendship and green thinking."
    },
    {
      id: "rising-water",
      title: "The Rising Water",
      image: book4Image,
      description: "Kuki & Mani investigate their canceled snake boat race, uncovering rising sea levels threatening their coastal home. Follow these young heroes as they learn about climate change and devise creative solutions in this compact adventure."
    },
    {
      id: "dadi-teaches",
      title: "Dadi Teaches a lesson",
      image: book5Image,
      description: "Dadi Devi's disappearance alarms the Gupta family. Their search becomes a wild adventure when Pinku parrot finds her secret letter. Join this exciting quest filled with surprises and valuable lessons."
    },
  ];

  return (
    <section id="books" className="section">
      <div className="container">
        <h2 className="section-title">Our Books</h2>
        <div className="book-grid">
          {books.map((book) => (
            <div key={book.id} className="book-card" onClick={() => {
                console.log('Book clicked:', book);
                openModal(book);
              }}>
              <img src={book.image} alt={book.title} className="book-image" />
              <div className="book-content">
                <h3 className="book-title">{book.title}</h3>
                <p className="book-description">{book.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="playcards">
          <h3 className="playcards-title">Environment & Climate Professions Flashcards</h3>
          <div className="playcards-content">
            <div className="playcards-images">
              <img src={ProductCatalog1} alt="Playcard 1" />
              <img src={ProductCatalog2} alt="Playcard 2" />
              <img src={ProductCatalog3} alt="Playcard 3" />
            </div>
          </div>
          <p className="playcards-description">Introducing our new Environment & Climate Professions Flashcards! These fun and educational cards showcase a variety of climate-related jobs, from environmental scientists to renewable energy engineers. Each card features engaging content to help children explore exciting careers in environmental protection and sustainability. With colorful illustrations and simple descriptions, our flashcards make learning about green careers enjoyable and accessible for young minds.</p>
          <button className="playcards-cta" onClick={openPlaycardsModal}>
            Explore Flashcards
          </button>
        </div>
      </div>
    </section>
  );
};

export default Books;
