import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const BookModal = ({ book, closeModal }) => {
    console.log('Rendering BookModal', book);
    if (!book) return null;
  

  return (
    <div className="modal"  style={{display: 'block'}}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <span className="close" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <img src={book.image} alt={book.title} className="modal-image" />
        <h3>{book.title}</h3>
        <p className="modal-description">{book.description}</p>
        <a href="https://amzn.in/d/0hAReniU" className="order-button" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faShoppingCart} /> Order Now from Amazon
        </a>
      </div>
    </div>
  );
};

export default BookModal;
