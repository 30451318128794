import React from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <header>
      <div className="container">
        <nav>
          <div className="logo">Reshuka Jain</div>
          <div className="nav-links">
            <Link to="climate-change" smooth={true} duration={500}>Climate Change</Link>
            <Link to="books" smooth={true} duration={500}>Books</Link>
            <Link to="testimonials" smooth={true} duration={500}>Testimonials</Link>
            <Link to="about-author" smooth={true} duration={500}>About Author</Link>
            <Link to="contact" smooth={true} duration={500}>Contact Us</Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
