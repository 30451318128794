
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxBKUL4GvCnWwmzluy45b_xRRoR5MDBK7hOi2F1h_1QOywP3DdYmu2MXMnaaRCBdGc/exec', {
        method: 'POST',
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message. Please try again.');
    }
  };
  return (
    <section id="contact" className="section">
      <div className="container">
        <h2 className="section-title">Get in Touch</h2>
        <div className="contact-content">
          <div className="contact-info">
            <div className="contact-method">
              <span className="contact-icon"><FontAwesomeIcon icon={faEnvelope} /></span>
              <div className="contact-details">
                <h3>Email Us</h3>
                <p>support.reshukajain@gmail.com</p>
              </div>
            </div>
            <div className="contact-method">
              <span className="contact-icon"><FontAwesomeIcon icon={faWhatsapp} /></span>
              <div className="contact-details">
                <h3>WhatsApp Us</h3>
                <p>+91 63633 29912</p>
              </div>
            </div>
            <div className="contact-method">
              <span className="contact-icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
              <div className="contact-details">
                <h3>Visit Us</h3>
                <p>Nallagandala, Hyderabad</p>
              </div>
            </div>
            <div className="contact-method">
              <span className="contact-icon"><FontAwesomeIcon icon={faInstagram} /></span>
                <div className="contact-details">
                  < h3>Follow Kuki & Mani</h3>
                  <a href="https://www.instagram.com/kukiandmani?igsh=cHRyaXg3Nzd1ZXdo&utm_source=qr" target="_blank" rel="noopener noreferrer">@kukiandmani</a>
                </div>
            </div>
          </div>
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" id="name" value={formData.name} onChange={handleChange} placeholder=" " required />
                <label htmlFor="name">Your Name</label>
              </div>
              <div className="form-group">
                <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder=" " required />
                <label htmlFor="email">Your Email</label>
              </div>
              <div className="form-group">
                <textarea id="message" rows="5" value={formData.message} onChange={handleChange} placeholder=" " required></textarea>
                <label htmlFor="message">Your Message</label>
              </div>
              <button type="submit" className="submit-btn">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
