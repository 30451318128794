// src/App.js
import React, { useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import ClimateChange from './components/ClimateChange';
import Books from './components/Books';
import Testimonials from './components/Testimonials';
import AboutAuthor from './components/AboutAuthor';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import BookModal from './components/BookModal';
import PlaycardsModal from './components/PlaycardsModal';

function App() {
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaycardsModalOpen, setIsPlaycardsModalOpen] = useState(false);

  const openModal = (book) => {
    setSelectedBook(book);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBook(null);
    setIsModalOpen(false);
  };

  const openPlaycardsModal = () => {
    setIsPlaycardsModalOpen(true);
  };

  const closePlaycardsModal = () => {
    setIsPlaycardsModalOpen(false);
  };

  return (
    <div className="App">
      <Header />
      <main className="container">
        <Hero />
        <ClimateChange />
        <Books openModal={openModal} openPlaycardsModal={openPlaycardsModal} />
        <Testimonials />
        <AboutAuthor />
        <Contact />
      </main>
      <Footer />
      <ScrollTop />
      {isModalOpen && <BookModal book={selectedBook} closeModal={closeModal} />}
      {isPlaycardsModalOpen && <PlaycardsModal closeModal={closePlaycardsModal} />}
    </div>
  );
}

export default App;
